import axios from "axios";
import { getAuthToken } from "./auth";

const logIn = (payload) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/users/login`,
    data: payload,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config);
};
const register = (payload) => {
  const token = getAuthToken();
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/users/register`,
    data: payload,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios(config);
};

const usersService = { logIn, register };
export default usersService;
