import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./new-student.css";
import usersService from "../../services/usersService";
// import coursesService from "../../services/coursesService";
import { useFormik } from "formik";
import * as yup from "yup";
import Toastr from "../../components/toastr/Toastr";
//bootstrap
import Table from "react-bootstrap/Table";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
//mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function NewStudent() {
  const navigate = useNavigate();
  const [courses] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [toast, setToast] = useState({
    isShow: false,
    style: "",
    status: "",
    msg: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .min(2, "Min 2 characters")
      .max(50, "Max 50 characters")
      .required("First name required"),
    lastName: yup
      .string()
      .min(2, "Min 2 characters")
      .max(50, "Max 50 characters")
      .required("Last name required"),
    email: yup
      .string("Enter your Email")
      .min(5, "Min 5 characters")
      .max(50, "Max 50 characters")
      .email()
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Min 8 characters")
      .max(100, "Max 100 characters")
      .required("Password is required"),
    role: yup
      .string("Enter a user role")
      .min(5, "Min 5 characters")
      .required("Role is required. Ex: student or admin"),
    avatarUrl: yup
      .string("Enter your avatar url")
      .min(5, "Min 5 characters")
      .max(255, "Max 255 characters"),
  });
  const [form] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    avatarUrl: "",
  });
  const formik = useFormik({
    initialValues: form,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      usersService.register(values).then(onRegisterSuccess);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  function onRegisterSuccess(response) {
    const idCreated = response.data.item;
    setToast((prevToast) => {
      let newToast = { ...prevToast };
      newToast.isShow = !prevToast.isShow;
      newToast.status = `Status ${response.status}`;
      newToast.style = "success";
      newToast.msg = `Item: ${idCreated} created!`;
      return newToast;
    });
    setActiveId((prevId) => {
      let newId = prevId;
      newId = response.data.item;
      return newId;
    });
    navigate(`${idCreated}`, { replace: true });
  }

  useEffect(() => {
    // coursesService.getCoursesByLangv2("english").then(onGetCoursesSuccess);
  }, []);

  // function onGetCoursesSuccess(response) {
  //   const courses = response.data.items;
  //   setCourses(courses);
  // }
  false && console.log(activeId);
  false && console.log(courses);
  function toggleToastr() {
    setToast((prevToast) => {
      let newToast = { ...prevToast };
      newToast.isShow = !prevToast.isShow;
      newToast.style = "";
      newToast.status = "";
      newToast.msg = "";
      return newToast;
    });
  }

  const courseArr = [
    {
      id: 1,
      name: "Windows",
      imgUrl:
        "https://media.istockphoto.com/id/471179856/photo/microsoft-sign-at-the-entrance-of-their-silicon-valley-campus.jpg?b=1&s=170667a&w=0&k=20&c=o4pWFnS7b_FDWYWiBeZeIIyeXzW-43q7uquLPwEwrK0=                                                                       ",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas ducimus explicabo est fugiat modi officiis. Sequi quisquam impedit asperiores ea quasi ut explicabo ducimus consequuntur itaque in amet voluptatum autem, ad ipsa molestias adipisci error voluptate quod officiis perspiciatis quidem animi eligendi doloribus delectus! Molestias exercitationem ut velit error amet.",
      category: "computer",
      courseClasses: [
        {
          id: 1,
          courseId: 1,
          title: "Windows Operating System",
          hours: 3,
          description:
            "Microsoft Windows is the most widely used operating system. 72% of personal computers use Windows.",
          imgUrl:
            "https://media.istockphoto.com/id/1498823842/photo/surfae-laptop-studio-photos-for-editorial-use-only.jpg?b=1&s=170667a&w=0&k=20&c=oGZi25laFhLTDHtJIaX5qRC_Tpb4PJ1H2Vhs6o_VIYA=",
          features: [
            {
              featureOne: "Components of a computer",
              featureTwo: "Learn how Windows works with hardware",
              featureThree: "Managing applications and settings",
              featureFour: "Personalizing your work environment",
            },
          ],
        },
        {
          id: 2,
          courseId: 1,
          title: "Windows File Management",
          hours: 3,
          description:
            "Learn how to create and organize documents, pictures, videos & more.",
          imgUrl:
            "https://media.istockphoto.com/id/1335050732/photo/businessman-using-a-computer-to-document-management-concept-online-documentation-database-and.webp?b=1&s=170667a&w=0&k=20&c=6eJ_nYSg_9m_vJ_usNLPnwvhoq23XYI6cE9NZv18Z3A=",
          features: [
            {
              featureOne: "Learn what a basic file structure is",
              featureTwo:
                "Save files created locally or those that are downloaded",
              featureThree: "Back-up your files to the cloud or device",
              featureFour: "Protect and hide sensitive documents",
            },
          ],
        },
      ],
      dateModified: "2024-08-22T22:23:25.4166667",
      dateCreated: "2024-08-06T03:36:37.6833333",
    },
    {
      id: 2,
      name: "Mac OS",
      imgUrl:
        "https://free-images.com/sm/ea7a/apple_mac_mini_computer.jpg                                                                                                                                                                                                    ",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas ducimus explicabo est fugiat modi officiis. Sequi quisquam impedit asperiores ea quasi ut explicabo ducimus consequuntur itaque in amet voluptatum autem, ad ipsa molestias adipisci error voluptate quod officiis perspiciatis quidem animi eligendi doloribus delectus! Molestias exercitationem ut velit error amet.",
      category: "computer",
      courseClasses: [
        {
          id: 3,
          courseId: 2,
          title: "Mac Operating System",
          hours: 3,
          description:
            "The most user friendly and secure operating system. Designed by Steve Jobs & Team",
          imgUrl:
            "https://free-images.com/sm/a587/apple_desk_technology_computer.jpg",
          features: [
            {
              featureOne: "Components of a computer",
              featureTwo: "Learn how MacOS works with hardware",
              featureThree: "Managing applications and settings",
              featureFour: "Personalizing your work environment",
            },
          ],
        },
        {
          id: 4,
          courseId: 2,
          title: "MacOS File Management",
          hours: 3,
          description:
            "Learn how to create and organize documents, pictures, videos & more.",
          imgUrl: "https://free-images.com/sm/ea7a/apple_mac_mini_computer.jpg",
          features: [
            {
              featureOne: "Learn what a basic file structure is",
              featureTwo:
                "Save files created locally or those that are downloaded",
              featureThree: "Back-up your files to the cloud or device",
              featureFour: "Protect and hide sensitive documents",
            },
          ],
        },
      ],
      dateModified: "2024-08-06T03:46:57.59",
      dateCreated: "2024-08-06T03:46:57.59",
    },
  ];
  function classMapper() {
    let masterList = [];
    courseArr.forEach((course) => {
      let classArray = course.courseClasses;
      classArray.forEach((prevClass) => {
        let newClass = { ...prevClass };
        newClass["classId"] = prevClass["id"];
        delete newClass["id"];
        newClass.course = course.name;
        newClass.category = course.category;
        masterList.push(newClass);
      });
    });
    return masterList;
  }

  useEffect(() => {
    console.log(classMapper());
    // eslint-disable-next-line
  }, []);
  false && console.log(courseArr);
  function EnrollmentTableData() {
    return (
      <>
        <td>online</td>
        <td>Yes</td>
        <td>No</td>
        <td>test</td>
      </>
    );
  }
  return (
    <>
      <div className="container my-5 fs-2 my-login-container">
        <Toastr toggleToastr={toggleToastr} toast={toast} />
        <div className="row">
          <div className="col-sm mb-3 d-flex justify-content-center">
            <h1 className="header-wt">Add Student</h1>
          </div>
          <div className="col-md">
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  autoComplete="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  className="mb-2"
                />
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  autoComplete="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  className="mb-2"
                />
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  autoComplete="tm-student-email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  className="mb-2"
                />
                <FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="mb-4"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    type={showPassword ? "text" : "password"}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helpertext={
                      formik.touched.password && formik.errors.password
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText error>
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  fullWidth
                  id="role"
                  name="role"
                  label="Role"
                  autoComplete="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                  className="mb-2"
                />
                <TextField
                  sx={{ m: 1, width: "25ch" }}
                  fullWidth
                  id="avatarUrl"
                  name="avatarUrl"
                  label="Avatar Url"
                  autoComplete="avatarUrl"
                  value={formik.values.avatarUrl}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.avatarUrl && Boolean(formik.errors.avatarUrl)
                  }
                  helperText={
                    formik.touched.avatarUrl && formik.errors.avatarUrl
                  }
                  className="mb-2"
                />
                <Button
                  sx={{ m: 1, width: "25ch" }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </Box>
          </div>
        </div>
        {/* table */}
        <div className="row">
          <Table responsive="sm" hover size="sm">
            <thead>
              <tr className="class-table-text">
                <th>Course</th>
                <th>Class Id</th>
                <th>Class Title</th>
                <th>Category</th>
                <th>Hours</th>
                <th>Plan</th>
                <th>Started</th>
                <th>Completed</th>
                <th>Enroll</th>
              </tr>
            </thead>
            <tbody>
              {classMapper().map((cClass, idx) => {
                //if cClass.classId = student classId then hydrate <EnrollmentTableData/>
                return (
                  <tr className="class-table-text" id={idx}>
                    <td>{cClass.course}</td>
                    <td>{cClass.classId}</td>
                    <td>{cClass.title} </td>
                    <td>{cClass.category}</td>
                    <td>{cClass.hours}</td>
                    <EnrollmentTableData />
                    {/* <td>Online</td>
                <td>Yes</td>
                <td>No</td>
                <td>Enroll</td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
export default NewStudent;
