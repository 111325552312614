import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function Toastr(props) {
  //#region Toastr setup
  //INCOMING PROPS:
  //toast:{
  //   isShow: false,
  //   style: "",
  //   status: "",
  //   msg: "",
  // }

  //OUTGOING PROPS: toggleToastr()

  //PARENT SETUP
  // const [toast, setToast] = useState({
  //   isShow: false,
  //   style: "",
  //   status: "",
  //   msg: "",
  // });

  // function toggleToastr() {
  //   setToast((prevToast) => {
  //     let newToast = { ...prevToast };
  //     newToast.isShow = !prevToast.isShow;
  //     newToast.style = "";
  //     newToast.status = "";
  //     newToast.msg = "";
  //     return newToast;
  //   });
  // }

  //<Toastr toggleToastr={toggleToastr} toast={toast} />
  //#endregion Toastr setup
  function onCloseToast() {
    props.toggleToastr();
  }
  return (
    <div aria-live="polite" aria-atomic="true" className="position-relative">
      {" "}
      <ToastContainer className="p-3" position="top-end" style={{ zIndex: 1 }}>
        <Toast
          show={props.toast?.isShow}
          onClose={onCloseToast}
          delay={10000}
          autohide
          animation={false}
          bg={props.toast?.style}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Code: {props.toast?.status}</strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>{props.toast?.msg}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
export default Toastr;
