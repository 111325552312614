import { jwtDecode } from "jwt-decode";
import { redirect } from "react-router-dom";

export function getAuthToken() {
  const token = localStorage.getItem("tutorMioToken");
  return token;
}

export function tokenLoader() {
  return getAuthToken();
}

export function checkAdmin() {
  let result = null;
  const token = getAuthToken();
  if (!token) {
    return redirect("/login");
  } else if (token) {
    const role = getUserRole(token);
    if (role === "admin") {
      result = null;
    } else return redirect("/login");
  }
  return result;
}
export function checkStudent() {
  let result = null;
  const token = getAuthToken();
  if (!token) {
    return redirect("/login");
  } else if (token) {
    const role = getUserRole(token);
    if (role === "student") {
      result = null;
    } else return redirect("/login");
  }
  return result;
}

function getUserRole(token) {
  const decodedToken = jwtDecode(token);
  return decodedToken.role;
}
