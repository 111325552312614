// Hero translations

const english = {
  typewriter: [
    "Windows / MacOS",
    "navigating The Web",
    "e-mail",
    "mobile device",
    "video conferencing",
    "Spreadsheets",
    "new technology!",
  ],
  welcome: "Welcome to Tutor Mio!",
  title: "In-person and online private tutoring",
  learn: "Learn",
  reservationBtn: "Make Reservation",
};

const spanish = {
  typewriter: [
    "Windows / MacOS",
    "navegar el Internet",
    "correo electrónico",
    "dispositivo móvil",
    "video-conferencia",
    "hojas de cálculo",
    "nueva tecnología!",
  ],
  welcome: "Bienvenido a Tutor Mio!",
  title: "Tutoría privada en persona o por internet",
  learn: "Descubre",
  reservationBtn: "Hacer reservación",
};

export { english, spanish };
