import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./sidebar.css";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useNavigate } from "react-router-dom";

function SideBar(props) {
  const navigate = useNavigate();

  const ifMobileWith = window.innerWidth < 480;

  const [user, setUser] = useState({
    firstName: "firstName",
    lastName: "lastName",
    userId: null,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const user = props.user;
    setUser((prevUser) => {
      let newUser = { ...prevUser };
      newUser.firstName = user.firstName;
      newUser.lastName = user.lastName;
      newUser.userId = user.userId;
      return newUser;
    });
    // eslint-disable-next-line
  }, [props.user.id, open]);

  const students = ["Maria", "Jose"];

  const onDropDown = () => {
    setOpen(!open);
  };

  const onMenuSelection = (selection) => {
    navigate(selection);
    props.toggleSidebar();
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={() => {
          onMenuSelection(null);
        }}
        scroll={true}
        backdrop={true}
        className="offcanvas-custom-width"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {ifMobileWith ? (
              false
            ) : (
              <>
                <h3 className="text-dark">{`${user.firstName} ${user.lastName}`}</h3>
              </>
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              <ListItem
                key="Courses"
                disablePadding
                onClick={() => {
                  onMenuSelection("courses");
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit Courses" />
                </ListItemButton>
              </ListItem>

              <ListItem
                key="Plans"
                disablePadding
                onClick={() => {
                  onMenuSelection("plans");
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit plans" />
                </ListItemButton>
              </ListItem>

              <ListItem
                key="new-student"
                disablePadding
                onClick={() => {
                  onMenuSelection("students/new");
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Register Student" />
                </ListItemButton>
              </ListItem>

              <ListItemButton onClick={onDropDown}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Students" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {students.map((student, idx) => {
                    student.toLowerCase();
                    return (
                      <ListItemButton
                        key={idx}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          onMenuSelection(student);
                        }}
                      >
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary={student} />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </List>
            <Divider />
          </Box>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default SideBar;
