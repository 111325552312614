// @flow
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import enFlag from "./flags/us.jpg";
import spainFlag from "./flags/spain.jpg";
import "./language-dropdown.css";

const Languages = [
  {
    name: "english",
    flag: enFlag,
  },
  {
    name: "spanish",
    flag: spainFlag,
  },
];

const LanguageDropdown = (props) => {
  const targetLang = Languages.filter((langObj) => {
    return langObj.name === props.tunnelingLang;
  });
  const [currentLang, setCurrentLang] = useState(targetLang[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    updateDropdown(props.tunnelingLang);
  }, [props.tunnelingLang]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function onLangSelected(targetLang) {
    props.bubblingLang(targetLang);
  }
  function updateDropdown(targetLang) {
    const targetLangObj = Languages.filter((langObj) => {
      return langObj.name === targetLang;
    });
    setCurrentLang((prevState) => {
      let newState = { ...prevState };
      newState = targetLangObj[0];
      return newState;
    });
  }

  return (
    <Dropdown show={dropdownOpen} onToggle={toggleDropdown} className="pb-1">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-languages"
        onClick={toggleDropdown}
        className="nav-link dropdown-toggle arrow-none"
      >
        <img
          src={currentLang.flag}
          alt={currentLang.name}
          className="me-0 me-sm-1"
          height="12"
        />{" "}
        <span className="align-middle d-sm-inline-block lang-dropdown-font text-white">
          {currentLang.name}
        </span>
        <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">
        <div onClick={toggleDropdown}>
          {Languages.map((lang, i) => {
            return (
              <Link
                to="#"
                className="dropdown-item notify-item"
                key={i + "-lang"}
                onClick={() => {
                  onLangSelected(lang.name);
                }}
              >
                <img
                  src={lang.flag}
                  alt={lang.name}
                  className="me-1"
                  height="12"
                />{" "}
                <span className="align-middle">{lang.name}</span>
              </Link>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
