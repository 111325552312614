import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "./footer.css";
import { spanish } from "./translation";

const Footer = (props) => {
  const targetLang = props.language;
  const [lang, setLang] = useState(spanish);
  useEffect(() => {
    import("./translation.js").then((module) => {
      setLang(module[`${targetLang}`]);
    });
  }, [targetLang]);
  return (
    <footer className="my-background py-5">
      <Container>
        <Row>
          {/* message */}
          <Col lg={7} className="px-5">
            <p className="text-muted mt-4">
              {lang.msg1}
              {lang.msg2}
              {lang.msg3}
              {lang.msg4}
            </p>
          </Col>
          {/* discover */}
          <Col lg={2} md={4} className="mt-3 mt-lg-0">
            <h5 className="text-light">{lang.discover}</h5>
            <ul className="list-unstyled ps-0 mb-0 mt-3">
              <li className="mt-2">
                <Link to="/courses" className="text-muted">
                  {lang.courses}
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/plans" className="text-muted">
                  {lang.plans}
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/about" className="text-muted">
                  {targetLang === "spanish" ? "Sobre mi" : "About me"}
                </Link>
              </li>
            </ul>
          </Col>
          {/* contact */}
          <Col lg={3} md={6} className="mt-3 mt-lg-0">
            <h5 className="text-light">{lang.contact}</h5>

            <ul className="list-unstyled ps-0 mb-0 mt-3">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={(e) => {
                    window.location.href = "mailto:oscarr.developer@gmail.com";
                    e.preventDefault();
                  }}
                >
                  oscarr.developer@gmail.com
                </Link>
              </li>
              <li className="mt-2">
                <Link to="tel:5622376847" className="text-muted">
                  1(562) 548-0315
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/contact" className="text-muted">
                  {lang.contactUs}
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="mt-5">
              <p className="text-muted mt-4 text-center mb-0">
                © 2024 Tutor Mio. {lang.copyright}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
