//footer translation

const english = {
  msg1: "In the modern era, computers have become an integral part of our ",
  msg2: "daily lives, and having a solid understanding of computer skills ",
  msg3: "has become crucial for personal, academic, and professional success. ",
  msg4: "My goal is to help you be a part of that success.",
  contact: "Contact",
  contactUs: "Contact Us",
  discover: "Discover",
  courses: "Courses",
  plans: "Plans",
  copyright: "Designed and coded by Oscar",
};

const spanish = {
  msg1: "En la era moderna, las computadoras se han convertido en una parte ",
  msg2: "integral de nuestra vida diaria y tener un conocimiento sólido de las ",
  msg3: "habilidades informáticas se ha vuelto crucial para el éxito personal, ",
  msg4: "académico y profesional. Mi objetivo es ayudarle a ser parte de ese éxito.",
  contact: "Contacto",
  contactUs: "Contáctanos",
  discover: "Descubre",
  courses: "Cursos",
  plans: "Planes",
  copyright: "Diseñado y codificado por Oscar",
};

export { spanish, english };
