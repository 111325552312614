import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";
import SideBar from "./SideBar";

function StudentLayout(props) {
  const [show, setShow] = useState(false);

  const toggleSidebar = () => {
    setShow((show) => !show);
  };

  return (
    <>
      <SideBar toggleSidebar={toggleSidebar} show={show} user={props.user} />
      <NavBar
        user={props.user}
        navigateToClass={props.navigateToClass}
        clearWishList={props.clearWishList}
        removeClass={props.removeClass}
        removePlan={props.removePlan}
        bubblingLang={props.bubblingLang}
        updateUser={props.updateUser}
        toggleSidebar={toggleSidebar}
        logOutUser={props.logOutUser}
      />

      <main>
        <h6>Student Layou</h6>
        <Outlet />
      </main>
      <Footer language={props.user.language} />
    </>
  );
}

export default StudentLayout;
