// @flow
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Typewriter from "./Typewriter";
import "./hero.css";
import image1 from "../../assets/images/startup.svg";
import { spanish } from "./translation";

const Hero = (props) => {
  const [lang, setLang] = useState(spanish);
  useEffect(() => {
    import("./translation.js").then((module) => {
      setLang(module[`${props.language}`]);
    });
  }, [props.language]);
  return (
    <section className="hero-section background-color my-hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={5}>
            <div className="mt-md-4">
              <div>
                <span className="text-white ms-1">{lang.welcome}</span>
              </div>
              <h2 className="text-white fw-normal mb-4 mt-3 hero-title">
                {lang.title}
              </h2>

              <h5 className="mb-4 font-16 text-white">
                {lang.learn} <Typewriter words={lang.typewriter} />
              </h5>

              <Link to="/contact" className="btn btn-warning ">
                {lang.reservationBtn}
              </Link>
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <div className="text-md-end mt-md-0">
              <img
                src={image1}
                alt=""
                className="img-fluid m-1"
                style={{ height: "25em" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
