// @flow
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useRouteLoaderData } from "react-router-dom";
import "./navbar.css";
import LanguageDropdown from "../language-dropdown/LanguageDropdown";
import logo from "../../assets/images/tutormio logo no background3.png";
import WishListDropDown from "../wishlist/WishListDropDown";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const NavBar = (props) => {
  const token = useRouteLoaderData(props.user.role);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lang, setLang] = useState(() => {
    import("./translation.js").then((module) => {
      return module[`${props.user.language}`];
    });
  });

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    import("./translation.js").then((module) => {
      setLang(module[`${props.user.language}`]);
    });
  }, [props.user.language]);

  function logOutHandler() {
    setExpanded((expanded) => !expanded);
    props.logOutUser();
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        id="nav-bottom-shadow"
        expand="lg"
        variant="dark"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        className={
          scrollPosition >= 90
            ? "py-lg-3 sticky-top scrolled-bg"
            : "py-lg-3 sticky-top original-bg"
        }
      >
        <Container>
          {token && (
            <Navbar.Brand className="sidebar-toggle-btn sidebar-btn-border px-2 ms-1">
              <KeyboardDoubleArrowRightIcon
                className=""
                onClick={() => {
                  props.toggleSidebar();
                }}
              />
            </Navbar.Brand>
          )}
          {/* Logo */}
          <Navbar.Brand className="me-lg-5 logo-centered">
            <NavLink to="" className="block-shadow ">
              <img src={logo} alt="" className="logo-dark " height="30em" />
            </NavLink>
          </Navbar.Brand>
          {/* Language Dropdown */}
          <Navbar.Brand className="d-none d-lg-inline">
            <LanguageDropdown
              tunnelingLang={props.user.language}
              bubblingLang={props.bubblingLang}
            />
          </Navbar.Brand>

          {/* toggle navbar dropdown */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          {/* Home Courses Plans Contact */}
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav
              as="ul"
              className="me-auto d-flex justify-content-between flex-row pt-4 pt-lg-0"
            >
              <Nav.Item className="mx-lg-1">
                <NavLink
                  to=""
                  end
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link "
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.home}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mx-lg-1">
                <NavLink
                  to="courses"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.courses}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mx-lg-1">
                <NavLink
                  to="plans"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.plans}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mx-lg-1">
                <NavLink
                  to="contact"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.contactUs}
                </NavLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>

          {/* Wishlist, Language, Login */}
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <div className="my-right">
              <Nav
                as="ul"
                className="me-auto d-flex justify-content-between flex-row pt-4 pt-lg-0"
              >
                <Nav.Item className="d-inline d-lg-none">
                  <LanguageDropdown
                    tunnelingLang={props.user.language}
                    bubblingLang={props.bubblingLang}
                  />
                </Nav.Item>
                <Nav.Item className="mx-lg-1">
                  <WishListDropDown
                    wishlist={props.user.wishlist}
                    activePlan={props.user.activePlan}
                    navigateToClass={props.navigateToClass}
                    clearWishList={props.clearWishList}
                    removeClass={props.removeClass}
                    removePlan={props.removePlan}
                    language={lang?.wishlist}
                  />
                </Nav.Item>
                <Nav.Item className="mx-lg-1">
                  {token ? (
                    <NavLink
                      className="logout-link pt-0"
                      onClick={logOutHandler}
                    >
                      <LockIcon />
                    </NavLink>
                  ) : (
                    <NavLink
                      to="/login"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active custom-nav-link pt-0"
                          : "nav-link custom-nav-link pt-0"
                      }
                      onClick={() => {
                        setExpanded((expanded) => false);
                      }}
                    >
                      <LockOpenIcon />
                    </NavLink>
                  )}
                </Nav.Item>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
