import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./wishlist-dropdown.css";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useLocation, Link } from "react-router-dom";

function WishListDropDown(props) {
  const completePath = useLocation().pathname;
  function currentPathGetter() {
    const targetIdx = completePath.lastIndexOf("/");
    const currentPath = completePath.substring(targetIdx);
    return currentPath;
  }

  const navigate = useNavigate();
  const wishlistData = props.wishlist;
  const currentPath = currentPathGetter();

  const activePlan = props.activePlan;
  const lang = props.language;

  function onPlanSelected() {
    if (currentPath === "/plans") {
      return;
    } else if (currentPath !== "/plans") {
      navigate("plans");
    }
  }

  function onCourseClass(courseClass) {
    if (currentPath === "/courses") {
      props.navigateToClass(courseClass);
    } else if (currentPath !== "/courses") {
      const payload = { type: "COURSE_CLASS_OBJ", courseClass };
      navigate("courses", { state: payload });
    }
  }

  const activePlanComponent = activePlan.map(activePlanMapper);
  function activePlanMapper(activePlan) {
    return (
      <Container className="d-flex" key={`plan_key:${activePlan.name}`}>
        <Col xs={10}>
          <Dropdown.Item href="#" style={{ backgroundColor: "transparent" }}>
            <Card border="light">
              <Row className="p-1">
                <Col
                  xs={12}
                  onClick={() => {
                    onPlanSelected(activePlan);
                  }}
                >
                  <small
                    id="my-dropdown-link"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "100%",
                    }}
                  >
                    {activePlan.name}
                  </small>
                </Col>
              </Row>
            </Card>
          </Dropdown.Item>
        </Col>
        <Col xs={2}>
          <Row className="pt-2">
            <Col className="d-flex justify-content-end">
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-${"bottom"}`}>
                    {lang.dropdown.content.removeItem}
                  </Tooltip>
                }
              >
                <button
                  onClick={props.removePlan}
                  style={{
                    border: "none",
                    padding: "0px",
                    color: "dark",
                    backgroundColor: "inherit",
                  }}
                >
                  <RxCross2
                    className="myButton"
                    style={{ paddingTop: "3px" }}
                  />
                </button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }

  const wishlistComponents = wishlistData.map(wishlistDataMapper);

  function onClearAllList() {
    props.clearWishList(0, wishlistData.length, "clear-all");
  }
  function onRemoveClass(title) {
    const idx = wishlistData.findIndex(finder);
    function finder(cItem) {
      return cItem.title === title;
    }
    idx !== -1 && props.removeClass(idx, 1);
  }

  function wishlistDataMapper(item) {
    return (
      <Container className="d-flex" key={`${item.title + item.id}`}>
        <Col xs={10}>
          <Dropdown.Item href="#" style={{ backgroundColor: "transparent" }}>
            <Card border="light">
              <Row className="p-1">
                <Col
                  xs={10}
                  onClick={() => {
                    onCourseClass(item);
                  }}
                >
                  <small
                    id="my-dropdown-link"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "100%",
                    }}
                  >
                    {item.title}
                  </small>
                </Col>
                <Col xs={2}>
                  <small>{item.hours}</small>
                </Col>
              </Row>
            </Card>
          </Dropdown.Item>
        </Col>
        <Col xs={2}>
          <Row className="pt-2">
            <Col className="d-flex justify-content-end">
              <OverlayTrigger
                key="bottom"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${"bottom"}`}>
                    {lang?.dropdown.content.removeItem}
                  </Tooltip>
                }
              >
                <button
                  onClick={() => {
                    onRemoveClass(item.title);
                  }}
                  style={{
                    border: "none",
                    padding: "0px",
                    color: "dark",
                    backgroundColor: "inherit",
                  }}
                >
                  <RxCross2
                    className="myButton"
                    style={{ paddingTop: "3px" }}
                  />
                </button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }

  return (
    <div className="position-relative">
      <DropdownButton
        variant="primary"
        title={lang?.buttonName || " "}
        className="custom-dropdown"
        drop="start"
        size="sm"
        id="wishlist-dropdown"
      >
        <Row>
          <Col xs={7}>
            <Link to="/courses">
              <strong className="ps-5 ">
                {lang?.dropdown.headers.classes}
              </strong>
            </Link>
          </Col>
          <Col xs={2}>
            <small>
              {(wishlistData.length > 0 && lang?.dropdown.headers.hours) || " "}
            </small>
          </Col>
          <Col>
            <button
              onClick={onClearAllList}
              style={{
                border: "none",
                padding: "0px",
                color: "dark",
                backgroundColor: "inherit",
              }}
            >
              <small className="myButton">
                {(lang?.dropdown &&
                  (wishlistData.length > 0 || activePlan.length > 0
                    ? lang?.dropdown.headers.clearAll
                    : " ")) ||
                  " "}
              </small>
            </button>
          </Col>
        </Row>
        {wishlistData.length === 0 ? (
          <Row className="pt-3">
            <Col className="d-flex justify-content-center">
              <p className="text-secondary">
                {lang?.dropdown.content.emptyClasses}
              </p>
            </Col>
          </Row>
        ) : (
          wishlistComponents
        )}
        <hr className="mx-3" />
        {activePlan.length === 0 ? (
          <Row className="pt-3">
            <Col className="d-flex justify-content-center">
              <p className="text-secondary">
                {lang?.dropdown.content.emptyPlans}
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={8}>
                <strong className="ps-5 ">
                  {lang?.dropdown.planHeader.plan}
                </strong>
              </Col>
            </Row>
            {activePlanComponent}
          </>
        )}
      </DropdownButton>
      <Badge
        pill
        bg="danger"
        className="position-absolute top-0 start-100 translate-middle"
      >
        {wishlistData.length + activePlan.length}
      </Badge>
    </div>
  );
}

export default WishListDropDown;
