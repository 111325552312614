// @flow
import React from "react";
import Hero from "../../components/hero/Hero";

const Landing = ({ language }) => {
  return (
    <>
      <Hero language={language} />
    </>
  );
};

export default Landing;
