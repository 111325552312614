import React from "react";
import { useTypewriter } from "react-simple-typewriter";
function Typewriter(props) {
  const words = props.words;
  const [text] = useTypewriter({
    words: words,
    loop: 3,
    deleteSpeed: 20,
    delaySpeed: 1000,
  });

  return <span className="gold-text">{text}</span>;
}
export default Typewriter;
